<script lang="ts" setup>
defineProps<{
  class?: string;
}>();
const target = ref();
usePageNavigation(target, "list");
const type = useClaimPageType();

const { claims, isFetching, onSearchTermChanged, isFetchingNextPage, fetchNextPage } = useClaimFiltering();
</script>

<template>
  <nav
    id="list"
    ref="target"
    :class="
      cn(
        'relative z-50 min-w-60 w-[calc(100vw-240px)] sm:w-60 2xl:w-80',
        'h-full shrink-0 border-r border-strong flex flex-col relative bg-color-1',
        'max-sm:snap-start',
        $props.class
      )
    "
  >
    <div
      :class="
        cn('sticky z-20 top-0 h-12 border-b focus-within', 'flex items-center gap-3 shrink-0 bg-color-1')
      "
    >
      <Search
        routeQueryKey="search"
        :loading="isFetching"
        class="w-full"
        @termChanged="onSearchTermChanged"
      />
    </div>
    <div class="grow scroll-area">
      <InfiniteScroller
        :loading="isFetchingNextPage"
        :elementsLength="claims.length"
        @loadMore="fetchNextPage"
      >
        <ul class="p-2.5 flex flex-col gap-2.5">
          <template v-if="type === 'claims'">
            <ClaimGridItem v-for="claimItem in claims" :key="claimItem.id" :claim="claimItem" reduced />
          </template>
          <template v-else>
            <SubmissionListItem v-for="claimItem in claims" :key="claimItem.id" :claim="claimItem" />
          </template>
        </ul>
      </InfiniteScroller>
    </div>
  </nav>
</template>
