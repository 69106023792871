<script lang="ts" setup>
const document = ref();
usePageNavigation(document, "document");
</script>

<template>
  <ScrollWrapper>
    <Navigation />
    <ClaimList />
    <section
      id="document"
      ref="document"
      :class="
        cn(
          'w-[100vw] shrink-0 sm:max-xl:w-[50vw] xl:basis-0 xl:grow h-full flex flex-col border-r border-strong',
          'max-xl:snap-start @container'
        )
      "
    >
      <NuxtPage />
    </section>
    <ChatRoom :title="$t('channel.title')" class="w-[100vw] sm:max-xl:w-[50vw]" />
  </ScrollWrapper>
</template>
